<template>
  <div id="app">
    <!-- <Preloader></Preloader> -->
    <transition name="router-anim" @before-enter="beforeEnter">
      <keep-alive>
        <router-view class="home" name="HomePage"></router-view>
      </keep-alive>
    </transition>
    <transition name="router-anim" @before-enter="beforeEnter">
      <router-view class="glove-section" name="SingleGlove"></router-view>
    </transition>
    <transition name="router-anim" @before-enter="beforeEnter">
      <router-view class="member-section" name="SingleMember"></router-view>
    </transition>
  </div>
</template>
<script>
import Preloader from "@/components/widgets/Preloader.vue";
import HeaderMain from "@/components/layout/HeaderMain.vue";
import dateFormat from "dateformat";

const app_title =
  process.env.VUE_APP_ENV !== "production"
    ? process.env.VUE_APP_TITLE + " " + dateFormat(new Date())
    : "Wells Lamont Snow";

export default {
  name: "app",
  components: {
    HeaderMain,
    Preloader
  },
  methods: {
    beforeEnter() {
      this.$root.$emit("scrollBeforeEnter");
    }
  },
  metaInfo: {
    title: app_title,
    meta: [
      // The favicon is located in client/public alongside the base index.html
      { rel: "favicon", href: "/favicon.ico" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "Wells Lamont SNOW" },
      { property: "og:site_name", content: "Wells Lamont Snow" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "article" },
      // Should the the same as your canonical link, see below.
      { property: "og:url", content: "https://snow.wellslamont.com" },
      {
        property: "og:description",
        content: "Wells Lamont SNOW Gloves and SNOW Team Members."
      },
      // { property: 'og:image', content: 'https://www.thisisstolen.com/wp-content/uploads/2018/11/og_image.jpg' },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content: "Wells Lamont"
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "@wells_lamont" },
      { name: "twitter:title", content: "Wells Lamont Snow Gloves" },
      {
        name: "twitter:description",
        content: "Wells Lamont SNOW Gloves and SNOW Team Members."
      },
      // Your twitter handle, if you have one.
      { name: "twitter:creator", content: "@wells_lamont" },
      {
        name: "twitter:image:src",
        content:
          "https://snow.wellslamont.com/wp-content/uploads/2019/01/wells-lamont-social-share-1200x630.png"
      },

      // Google / Schema.org markup:
      { itemprop: "name", content: "Wells Lamont Snow" },
      {
        itemprop: "description",
        content: "Wells Lamont SNOW Gloves and SNOW Team Members."
      },
      {
        itemprop: "image",
        content:
          "https://snow.wellslamont.com/wp-content/uploads/2019/01/wells-lamont-social-share-1200x630.png"
      }
    ]
  }
};
</script>
<style>
@import url(https://use.typekit.net/rzb0jub.css);
@import "./assets/css/main.scss";
  
#partners.s-partners.New .punchout.levelone{
    height:295px;
}
  
#partners.s-partners .Desktop a.btn.btn--primary.BottomBtn{
    margin-left:195px !important;
}
#partners.s-partners .Desktop a.btn.btn--primary.TopButton {
    margin-left: 30px;
}

#partners.s-partners .Mobile {
  display: none;
}

@media(max-width:767px) {
    #partners.s-partners{
        background-image: url(https://snow.wellslamont.com/wp-content/uploads/2022/01/2020-wl-doggy-footer-mobilen.jpg) !important;
    }
    #partners.s-partners .Desktop{
        display: none !important;
    }
    #partners.s-partners .Mobile{
        display: block;
    }
    #partners.s-partners .Mobile img{
        width: 100%;
    }
    #partners.s-partners .Mobile a{
        margin:0!important;
        margin-top:10px!important;
        padding:10px 20px 5px 20px;
        font-size: 16px;
    }
    #partners.s-partners.New .punchout.levelone{
        height: 10px;
    }
    #partners.s-partners .Evo{
        margin-top: 10px;
    }
    #partners.s-partners.New .partners-box{
        padding:40% 0 20% 40% !important;
    }
    .col-xs-12.ss-copyright.text-center{
        padding-bottom:0;
        padding-left:15px;
        padding-right:15px
    }
}
.fade-in {
  opacity: 0;
  transition: opacity 2s;
}
.fade-in.image-loaded {
  opacity: 1;
}
.hamburger:focus {
  background-color: transparent;
  outline: none;
}
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  position: absolute;
  top: 10px;
  right: 5px;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-open:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 50px;
  height: 35px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 45px;
  height: 4px;
  background-color: white;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}
.hamburger--slider .hamburger-inner::before {
  top: 14px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 28px;
}
.hamburger--slider.is-open .hamburger-inner {
  transform: translate3d(0, 14px, 0) rotate(45deg);
}
.hamburger--slider.is-open .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-7.14286px, -10px, 0);
  opacity: 0;
}
.hamburger--slider.is-open .hamburger-inner::after {
  transform: translate3d(0, -28px, 0) rotate(-90deg);
}
#partners.s-partners {
  background-image: url(https://snow.wellslamont.com/wp-content/uploads/2021/12/2020-wl-doggy-footer.jpg);
}
.section-bg {
  position: absolute !important;
  top: 0;
  width: 100%;
}
.navbar .navbar-nav li a,
.home-content h1,
.home-content .display-3 {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
.navbar-collapse {
  transition: all 2s;
  /* opacity: 0; */
}
.show.navbar-collapse {
  /* opacity: 1; */
  transition: all 2s;
}
.navbar-button {
  background: url("./assets/images/navbar-icon.fcf87c34.svg") center center
    no-repeat;
  right: 0;
  position: absolute;
  top: 10px;
  height: 58px;
  width: auto;
  background-color: unset !important;
  outline: none !important;
}
.show.navbar-button {
  background: url("./assets/images/navbar-icon-open.9aa0577c.svg") center center
    no-repeat;
}
a:hover {
  cursor: pointer;
}
section {
  background-size: cover;
}
.team-member-box {
  position: relative;
}
.team-members .i-instagram {
  position: absolute;
  color: white;
  right: 5px;
  bottom: 5px;
  height: 20px;
  width: 20px;
  z-index: 10;
}
.team-members .i-instagram svg {
  color: white;
  height: 100%;
  width: 100%;
}
.nav-item.active a {
  position: relative;
  display: block;
}
.nav-item.active a:after {
  content: "";
  position: absolute;
  bottom: 1px;
  left: 0;
  height: 3px;
  width: 100%;
  background: #007cab;
  background: linear-gradient(to right, #007cab 0%, #007cab 50%, #007cab 100%);
}
.glove-section .nav-item img:hover {
  cursor: pointer;
}
.glove-box {
  height: 350px;
}
.glove-box img {
  height: 100%;
}
.connect-link {
  color: black;
}
.connect-link:hover {
  text-decoration: none;
}
/* iframe {
  border: none;
} */
#product.s-product {
  position: static !important;
  overflow-y: visible;
  height: auto;
  padding-top: 0;
}
.instagram-feed {
  transition: all 0.35s;
}
.instagram-feed:hover {
  opacity: 0.7;
}
.vimeo-modal .modal-default-button {
  width: 640px;
  text-align: right;
  margin: 0 auto;
  padding: 0;
}
.vimeo-modal .modal-default-button:hover {
  opacity: 0.8;
}
.vimeo-modal .modal-body {
  margin-top: 0;
}
.vimeo-player {
  text-align: center;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: auto;
  margin: 0px auto;
  padding: 20px 30px;
  border-radius: 2px;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column-reverse;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  text-align: center;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.modal-mask .modal-default-button {
  background: none;
}
.modal-mask .modal-default-button .fa-times-circle {
  height: 30px;
  width: 30px;
  color: white;
}
.modal-default-button:focus {
  outline: none;
}
.s-video .s-video-vimeo-nav .nav .nav-item:hover {
  cursor: pointer;
}
.s-member-profile-img .i-instagram {
  top: 13px;
  right: 20.5%;
  color: white;
  height: 27px;
  width: 27px;
  position: absolute;
}
.s-member-profile-img .i-instagram a {
  height: 100%;
  width: 100%;
  display: block;
  color: white;
  text-shadow: 1px 1px 1px solid black;
}
.s-member-profile-img .i-instagram a svg {
  height: 100%;
  width: 100%;
}
.s-member-info .i-close {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: -55px;
  right: -100px;
  width: 79px;
  height: 84px;
  background-color: white;
  z-index: 23;
}
.s-member-info .i-close a.close-member {
  height: 35px;
  width: 35px;
  color: black;
}
.s-member-info .i-close .close-member svg {
  height: 100%;
  width: 100%;
}
.instagram-feed .instagram-image-div {
  width: 260px;
  height: 260px;
  background-size: cover;
  background-position: center;
}
#snow-social .instagram-feed a svg {
  height: 18px;
  width: 18px;
  right: 6.5%;
  position: absolute;
  top: 2.5%;
  color: white;
}
.router-anim-enter-active {
  animation: coming 2s;
  animation-delay: 0.5s;
  opacity: 0;
}
.router-anim-enter-active:after {
  position: absolute;
  height: 100vh;
  width: 100vw;
}
.router-anim-leave-active {
  animation: going 1s;
}
img[lazy="loading"] {
  animation: coming 3s;
}
img[lazy="loaded"] {
  animation: coming 3s;
}
.background-fixed {
  background-attachment: fixed;
}
.lazy-animate[lazy="loading"] {
  animation: coming 3s;
}
.lazy-animate[lazy="loaded"] {
  animation: coming 3s;
}
.vimeo-item {
  display: inline-block;
  margin-right: 10px;
}
.vimeo-enter-active,
.vimeo-leave-active {
  transition: all 1s;
}
.vimeo-move {
  transition: transform 1s;
}
.vimeo-enter, .vimeo-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.s-member .member-header {
  height: 60vh;
  min-height: 60vh;
}
.vimeo-link:hover {
  opacity: 0.8 !important;
}
#s-product-pics-nav li {
  margin: 7px auto;
}
@keyframes going {
  from {
  }
  to {
    opacity: 0;
  }
}
@keyframes coming {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes comingUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@media only screen and (min-width: 1200px) {
  #about-hydrahyde .hydrahyde-box {
    right: 48vw;
    /* top: 20%; */
  }
}
@media only screen and (min-width: 992px) {
  .team-members {
    display: flex;
  }
  .team-members .team-member {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 25px;
  }
  .navbar {
    padding-left: 270px !important;
  }
  .s-gloves {
    padding: 200px 0px 150px 0px;
  }
  .s-video .s-video-vimeo-nav .nav {
    height: 147px;
  }
  .s-video .s-video-vimeo-nav .nav .nav-item {
    margin: 13px 0;
  }
  .s-video,
  .s-video .s-video-snow-cover {
    height: 90vh !important;
  }
  .glove-col {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 768px) {
  #about-hydrahyde {
    background: #ffffff
      url("https://snow.wellslamont.com/wp-content/uploads/2019/02/hydrahyde-bg-new.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .hamburger {
    display: none;
  }
  .wrapper .s-header {
    transition: opacity 0.5s;
  }
  .wrapper .s-header:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: black;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .wrapper .s-header.is-active:before {
    opacity: 1;
  }
  .s-header.is-active {
    opacity: 1;
  }
  .home-social-icons {
    height: auto;
  }
}
@media only screen and (max-width: 991px) {
  .glove-box {
    height: 250px;
  }
  .glove-box a img {
    width: 100%;
    height: auto;
  }
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .team-member {
    width: 50%;
    /* float: left; */
  }
}
@media only screen and (max-width: 767px) {
  .team-member-link {
    padding: 10px;
  }
  .team-member-link img {
    width: 100%;
  }
  .s-about .about-box .display-1 .h1.big-year {
    font-size: 16rem;
  }
  .s-footer .footer-box .footer-nav-wrap .footer-main-nav li {
    padding: 0 25px;
  }
  .s-product-footer-content .tab-pane.show {
    overflow: scroll;
  }
  .s-gloves .row-gloves .glove-box {
    padding: 35px 5px;
  }
  .glove-box {
    height: 200px;
  }
  .row-gloves .glove-col {
    padding: 0 !important;
  }
  #wlnav.collapse {
    transition: all 1s !important;
  }
  #wlnav.collapse.is-open {
    display: block;
  }
  #product .s-product-info .i-close {
    position: fixed;
    background: none;
    top: 0;
    right: 0;
  }
  #s-product-details #details {
    overflow: scroll;
  }
  .s-member-profile-img .i-instagram {
    right: 8.5%;
  }
  .s-member-profile-img img {
    width: 100%;
    height: auto;
  }
  .s-member-info .i-close {
    position: fixed;
    top: 0;
    right: 0;
    background: none;
  }
  /* .s-member-info .i-close svg {
    background: white;
    border-radius: 50%;
  } */
  .s-member .member-header {
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
  .navbar .navbar-nav {
    left: 0;
  }
  .instagram-feed .instagram-image-div {
    height: 150px;
    width: auto;
  }
  #snow-social .instagram-feed a svg {
    height: 28px;
    width: 28px;
    right: 14.5%;
    top: 6.5%;
  }
  #snow-social .instagram-feed {
    margin-bottom: 10px;
  }
  .team-member-box {
    padding: 0;
  }
  .team-members .i-instagram {
    right: 13%;
    bottom: 25%;
    height: 20px;
    width: 20px;
  }
}
@media only screen and (max-width: 576px) {
  #about.s-about .s-photo-credit {
    font-size: 1.2rem;
  }
  #app #home {
    height: 100vh;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  #wlteam .team-members {
    padding-top: 50px;
  }
  .s-footer .ss-copyright,
  .ss-copyright span {
    color: rgba(255, 255, 255, 0.7);
    line-height: 13px;
  }
  .s-footer .footer-box .footer-nav-wrap .footer-main-nav li {
    padding: 0 13px;
  }
  #gloves {
    padding-bottom: 30px;
  }
  #about {
    /* background-position: 76%; */
    background-repeat: no-repeat;
    background-position: center !important;
  }
  #about .about-box .display-1 .h1.big-year {
    font-size: 11rem;
  }
  .team-members .i-instagram {
    right: 3%;
    bottom: 18%;
  }
  /* #about-hydrahyde {
    background-size: 111%;
  }
  #about-hydrahyde .hydrahyde-box .hydrahyde-box-header h3 {
    font-size: 3rem;
    margin: 10px 0 0 0;
    width: 64%;
    display: block;
    padding-left: 6px;
  }
  #about-hydrahyde .hydrahyde-box .hydrahyde-box-header img {
    padding-left: 15%;
  }
  .s-about-hydrahyde .hydrahyde-box {
    padding-top: 140px;
  }
  .s-about-hydrahyde .hydrahyde-box .hydrahyde-box-header img {
    max-height: 52px;
    width: auto;
    padding-left: 20%;
  } */
}
</style>
