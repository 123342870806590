<template>
  <header :class="'s-header ' + navClass">
    <div class="container">
      <div class="header-container">
        <div class="header-logo-box">
          <div class="header-logo">
            <a class="site-logo" href="/">
              <img
                src="../../assets/images/logo.svg"
                width="135"
                height="81"
                alt="Wells Lamont SNOW"
                data-no-retina
              >
            </a>
          </div>
          <!---end header-logo-->
        </div>
        <!--end header-logo-box-->
        <nav class="navbar navbar-expand-md" role="navigation">
          <div :class="'collapse navbar-collapse' + menuStatus" id="wlnav">
            <ul class="navbar-nav justify-content-end nav-justified" @click="closeMenu">
              <li
                v-for="(item, index) in menuItems"
                :key="index"
                :class="'nav-item' + item.current"
              >
                <a
                  class="smoothscroll nav-link"
                  :href="baseUrl + '/#' + index"
                  :title="index"
                  v-smooth-scroll="item.vSmoothScroll"
                >{{ item.content }}</a>
              </li>
            </ul>
          </div>
          <!--end navbar-collapse-->
          <!-- <button :class="'navbar-button' + menuStatus" type="button" @click="toggleMenu()"></button> -->
          <button
            :class="'hamburger hamburger--slider ' + menuStatus"
            type="button"
            @click="toggleMenu()"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </nav>
        <!-- end header-nav-wrap -->
      </div>
      <!--end header-container-->
    </div>
    <!--end container-->
  </header>
  <!-- end header -->
</template>

<script>
export default {
  name: "HeaderMain",
  data() {
    return {
      vSmoothScroll: { duration: 1000 },
      navClass: "",
      menuStatus: "",
      menuItems: {
        snowvideo: {
          content: "Videos",
          current: "",
          vSmoothScroll: { duration: 1000, offset: -80 }
        },
        about: {
          content: "About",
          current: "",
          vSmoothScroll: { duration: 1000 }
        },
        wlteam: {
          content: "WL Team",
          current: "",
          vSmoothScroll: { duration: 1000, offset: -80 }
        },
        gloves: {
          content: "Gloves",
          current: "",
          vSmoothScroll: { duration: 1000, offset: -50 }
        },
        partners: {
          content: "Partners",
          current: "",
          vSmoothScroll: { duration: 1000 }
        }
      }
    };
  },
  mounted() {
    this.$root.$on("toggleStuff", on => {
      this.navClass = on ? "is-active" : "";
    });
    this.$root.$on("highlight", args => {
      this.menuItems[args[0].id].current = args[1];
    });
  },
  methods: {
    toggleMenu() {
      this.menuStatus =
        this.menuStatus == " is-open sticky" ? "" : " is-open sticky";
    },
    closeMenu() {
      this.menuStatus = "";
    },
    openMenu() {
      this.menuStatus = " show";
    }
  }
};
</script>