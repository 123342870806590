import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.name.startsWith('glove')) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 850)
      })
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        title: 'Home Page - Wells Lamont SNOW'
      },
      components: {
        HomePage: () => import('./components/routes/HomePage.vue')
      },
      props: { HomePage: true },
    },
    {
      path: '/team/:slug',
      name: 'member',
      components: {
        SingleMember: () => import('./components/routes/SingleMember.vue')
      },
      props: { SingleMember: true },
    },
    {
      path: '/glove/:slug',
      name: 'glove',
      components: {
        SingleGlove: () => import('./components/routes/SingleGlove.vue')
      },
      props: { SingleGlove: true }
    }
  ]
})
