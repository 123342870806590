import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'

// Media query, scroll to section on click, waypoint to trigger on scroll position
import VueMq from 'vue-mq'
import vueSmoothScroll from 'vue-smooth-scroll'
import VueWaypoint from 'vue-waypoint'
import Parallax from 'parallax-js'
import VueParallaxJs from 'vue-parallax-js'

Vue.use(VueParallaxJs)

// Eventually replace LazyLoad
import LazyImage from "@/components/helpers/LazyImage"
import VueLazyload from 'vue-lazyload'

import vueVimeoPlayer from 'vue-vimeo-player'

// SEO
import Meta from 'vue-meta'
import VueAnalytics from 'vue-analytics'

// Fontawesome
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faFacebookSquare,
    faTwitterSquare,
    faSpotify,
    faInstagram,
    faVimeo
} from '@fortawesome/free-brands-svg-icons'
import {
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'


Vue.component('lazy-image', LazyImage)
Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(faFacebookSquare, faTwitterSquare, faSpotify, faInstagram, faTimesCircle, faVimeo)

Vue.use(vueVimeoPlayer)
Vue.use(VueLazyload)
Vue.use(vueSmoothScroll)
Vue.use(VueAnalytics, {
    id: 'UA-35139871-2',
    router
})
Vue.use(VueMq, {
    breakpoints: {
        xs: 576,
        sm: 768,
        md: 992,
        lg: 1200,
        xl: Infinity
    }
})
// or with options
Vue.use(VueLazyload, {
    preLoad: 1.3,
    observer: true,
    // error: 'dist/error.png',
    loading: 'dist/loading.gif',
    attempt: 1,
    listenEvents: ['scroll']
})
Vue.use(VueWaypoint)
Vue.use(Meta);


Vue.mixin({
    data: function () {
        return {
            get baseUrl() {
                if (typeof process.env.VUE_APP_URL !== 'undefined') {
                    return process.env.VUE_APP_URL
                } else {
                    return 'http://localhost:8081'
                }
            }
        }
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')